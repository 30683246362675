import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout, Icon, message } from 'antd';
import options from './options';
import Scrollbars from '@components/utility/customScrollBar.js';
import Menu from '@components/uielements/menu';
import IntlMessages from '@components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '@redux/app/actions';
import Logo from '@components/utility/logo';
import themes from '@settings/themes';
import { themeConfig } from '@settings';
import HSBBClient from '@helpers/apiClient';
import { defineUserRoles } from '@helpers/utility';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }
  state = {
    reportOptions: null
  };
  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      let reportOptions = await HSBBClient.getSetting('REPORT_CONFIG');
      this.setState({
        reportOptions
      });
    } catch (err) {
      message.error('Error to fetch for report option');
    }
  };

  handleClick(e) {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }

  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find((key) => !(app.openKeys.indexOf(key) > -1));
    const latestCloseKey = app.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }

  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2']
    };
    return map[key] || [];
  };

  getMenuItem = ({ singleOption, submenuStyle, submenuColor, userRolesIn }) => {
    const { key, label, leftIcon, children } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <>
              <Icon type={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </>
          }
        >
          {children.map((child) => {
            if (userRolesIn(child.roles)) {
              const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
              return (
                <Menu.Item key={child.key}>
                  <Link to={linkTo}>
                    <span className="isoMenuHolder" style={submenuColor}>
                      <Icon type={child.leftIcon} />
                      <span className="nav-text">
                        {key === 'reports' ? child.label : <IntlMessages id={child.label} />}
                      </span>
                    </span>
                  </Link>
                </Menu.Item>
              );
            }
            return null;
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <Icon type={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { app, toggleOpenDrawer, height } = this.props;
    let userSetting = this.props.userSetting;
    let isAuthorizedUser = window.localStorage.getItem('isAuthorizedUser');
    const userRoles = userSetting.roles || [];
    const userRolesIn = defineUserRoles(userRoles);
    const userRole = this.props.userRole;
    if (!userSetting) {
      userSetting = {
        roles: ['guest']
      };
    }
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = (event) => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
    };
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };

    let dynamicOptions = [...options];

    if (userRoles[0] !== 'Alternate Channel') {
      dynamicOptions.filter((item) => {
        if (item.key === 'applications') {
          delete item.children;
        }
      });
    } else {
      let isHide = false;
      dynamicOptions.filter((item, index) => {
        if (item.key === 'applications') {
          // Digi 
          if (userSetting?.pulse_user_id === undefined || userSetting?.pulse_user_id === "") {
            delete item.children;
            isHide = true;
          }

          if (userSetting?.pulse_user_id !== undefined && userSetting?.celcom_store_id === undefined && userSetting?.storeId === undefined) {
            if (userRole !== null) {
              if (userRole?.MVIEWDIGI === 'False' && userRole?.MVIEWCELCOM === 'False') {
                delete item.children;
                isHide = true;
              }
              if (userRole?.MVIEWDIGI === 'False' && userRole?.MVIEWCELCOM === 'True') {
                delete item.children[0];
              }
              if (userRole?.MVIEWDIGI === 'True' && userRole?.MVIEWCELCOM === 'False') {
                delete item.children[1];
              }
            }
          }

          if (userSetting?.pulse_user_id !== undefined && userSetting?.celcom_store_id !== undefined) {
            if (userRole !== null) {
              if (userRole?.MVIEWDIGI === 'False' && userRole?.MVIEWCELCOM === 'False') {
                delete item.children;
                isHide = true;
              }
              if (userRole?.MVIEWDIGI === 'False' && userRole?.MVIEWCELCOM === 'True') {
                delete item.children[0];
              }
              if (userRole?.MVIEWDIGI === 'True' && userRole?.MVIEWCELCOM === 'False') {
                delete item.children[1];
              }
            }
          }
          if (isAuthorizedUser && isAuthorizedUser === 'false') {
            delete item.children;
            isHide = true;
          }
        }
      });

      if (isHide) {
        dynamicOptions.filter((item, index) => {
          if (item.key === 'applications') {
            delete dynamicOptions[index];
          }
        });
      }
    }

    if (this.state.reportOptions !== null) {
      let reportchildrens = [];
      let reportsMenuRoles = [];
      this.state.reportOptions.forEach((item) => {
        if (item.isEnabled === true) {
          let child = {};
          if (userRole?.MSALEREPORT && userRoles[0] === 'Alternate Channel' && item.reportName === 'Mass Sales Data') {
            child = {
              key: `reports/${item.reportId}`,
              label: item.reportName,
              leftIcon: item.iconImage,
              roles: [...item.roleAccess, 'Alternate Channel']
            };
          }
          if (userRoles[0] !== 'Alternate Channel') {
            child = {
              key: `reports/${item.reportId}`,
              label: item.reportName,
              leftIcon: item.iconImage,
              roles: [...item.roleAccess]
            };
          }
          reportsMenuRoles = [...reportsMenuRoles, ...item.roleAccess];
          if (!_.isEmpty(child)) {
            reportchildrens.push(child);
          }
        }
      });

      //Remove Duplicates
      reportsMenuRoles = [...new Set(reportsMenuRoles)];
      // if (userRoles[0] === 'Alternate Channel') {
      //   reportsMenuRoles.push('Alternate Channel');
      // }

      if (reportchildrens.length > 0) {
        let reportsMenu = {
          key: 'reports',
          label: 'sidebar.reports',
          leftIcon: 'solution',
          roles: reportsMenuRoles,
          children: reportchildrens
        };
        dynamicOptions.push(reportsMenu);
      }
    }

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="light"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {dynamicOptions.map((singleOption) => {
                if (singleOption.roles) {
                  if (userRolesIn(singleOption.roles)) {
                    return this.getMenuItem({ submenuStyle, submenuColor, singleOption, userRolesIn });
                  }
                  return null;
                } else {
                  return this.getMenuItem({ submenuStyle, submenuColor, singleOption, userRolesIn });
                }
              })}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    app: state.App,
    height: state.App.height,
    user: state.Auth.user,
    userSetting: state.Auth.userSetting
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
