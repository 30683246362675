import { all, takeEvery, put, fork, call, takeLatest } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { getToken } from '@helpers/utility';
import kc, { signOut, getUserProfile, loadKc } from '@helpers/keycloak';
import HSBBClient from '@helpers/apiClient';
import HSBBClientV2 from '@helpers/apiClient-v2';
import {
  clearToken,
  startListener,
  stopListener,
  clearKcCallback,
  checkIsTokenExpired,
  updateSessionTimeout
} from '@helpers/utility';

import actions from './actions';

export function* parseKc() {
  try {
    const userProfile = yield call(getUserProfile);

    yield put({
      type: actions.LOGIN_SUCCESS,
      accessToken: kc.token,
      profile: userProfile
    });
  } catch (error) {
    yield put({
      type: actions.LOGIN_ERROR,
      error
    });
  }
}

export function* handleAuthenticationCallback() {
  yield takeLatest(actions.HANDLE_AUTHENTICATION_CALLBACK, parseKc);
}

export function* login() {
  yield takeEvery(actions.LOGIN, function* () {
    const token = getToken();
    const accessToken = token.get('accessToken');

    if (accessToken) {
      yield put(actions.checkAuthorization());
      return;
    }

    yield call(loadKc);
    kc.login({
      redirectUri: process.env.REACT_APP_KEYCLOAK_CALLBACK_URL
    });
  });
}

export function* fetchUserRole(action) {
  try {
    const userData = yield call(HSBBClientV2.getUser, action.userId);

    // const userDataPayload = {
    //   userId: userData.data.userId,
    //   roles: userData.data.roles,
    //   celcomStoreId: userData.data.celcomStoreId,
    //   pulseUserId: userData.data.pulseUserId
    // };
    // const userRole = yield call(HSBBClientV2.getUserRoles, userDataPayload);

    yield put({
      type: actions.USER_ROLE_LOADED,
      userRole: !_.isEmpty(userRole.data.rolePermissions) ? userRole.data.rolePermissions : null,
      rolePosition: userRole.data.rolePosition !== '' ? userRole.data.rolePosition : '',
      isUserAuthorized:
        userData.data.roles.length > 0 && userData.data.roles.indexOf('Alternate Channel') !== -1
          ? userRole.data.isUserAuthorized
          : true,
      userRoleError: userRole.data.rolePermissionErrors.length > 0 ? userRole.data.rolePermissionErrors : null
    });
    // if (
    //   userData.data.roles.length > 0 &&
    //   userData.data.roles.indexOf('Alternate Channel') !== -1 &&
    //   !userRole.data.isUserAuthorized
    // ) {
    //   window.localStorage.setItem('isAuthorizedUser', userRole.data.isUserAuthorized);
    //   window.localStorage.setItem('unauthorizedError', userRole.data.rolePermissionErrors);
    // } else {
    //   window.localStorage.setItem('isAuthorizedUser', true);
    //   window.localStorage.setItem('unauthorizedError', '');
    // }
  } catch (error) {
    yield put({
      type: actions.USER_ROLE_ERROR,
      error
    });
  }
}

export function* userRole() {
  yield takeLatest(actions.USER_ROLE, fetchUserRole);
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield call(loadKc);

    const userSetting = yield call(HSBBClient.getUserSetting);
    window.localStorage.setItem('pulseId', userSetting.pulse_user_id);

    const userDataPayload = {
      userId: userSetting.userId,
      roles: userSetting.roles,
      celcomStoreId: userSetting.celcom_store_id,
      pulseUserId: userSetting.pulse_user_id,
    };
    const userRole = yield call(HSBBClientV2.getUserRoles, userDataPayload);
    if (
      userSetting.roles.length > 0 &&
      userSetting.roles.indexOf('Alternate Channel') !== -1 &&
      !userRole.data.isUserAuthorized
    ) {
      window.localStorage.setItem('isAuthorizedUser', userRole.data.isUserAuthorized);
      window.localStorage.setItem('unauthorizedError', userRole.data.rolePermissionErrors);
    } else {
      window.localStorage.setItem('isAuthorizedUser', true);
      window.localStorage.setItem('unauthorizedError', '');
    }

    const profile = payload.profile;

    yield put({
      type: actions.USER_PROFILE_LOADED,
      profile,
      userSetting
    });

    startListener();

    if (!payload.noRedirect) {
      yield put(push('/admin'));
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function (payload) {
    stopListener();
    clearKcCallback();
    clearToken();
    console.log(payload.error);
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    stopListener();
    clearToken();

    yield call(signOut);
  });
}

export function* redirect() {
  yield takeEvery(actions.REDIRECT, function* (payload) {
    yield put(replace(payload.link));
  });
}

export function* redirectToLogin() {
  yield takeEvery(actions.REDIRECT_TO_LOGIN, function* () {
    stopListener();
    clearToken();
    yield put(actions.redirect(process.env.REACT_APP_BASENAME));
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    try {
      const token = getToken();
      const accessToken = token.get('accessToken');
      const idToken = token.get('idToken');
      const refreshToken = token.get('refreshToken');

      if (!accessToken) {
        yield put(actions.redirectToLogin());
        return;
      }

      const sessionTimeout = window.localStorage.getItem('session_timeout');
      const now = new Date().getTime() / 1000;

      const isActive = !!sessionTimeout && sessionTimeout > now;
      const isAccessExpired = checkIsTokenExpired(accessToken);
      const isRefreshExpired = checkIsTokenExpired(refreshToken);
      const decodedAccessToken = jwtDecode(accessToken);

      const notValid =
        decodedAccessToken.iss !==
        process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL + '/realms/' + process.env.REACT_APP_KEYCLOAK_REALM;

      if (notValid || isRefreshExpired) {
        yield put(actions.redirectToLogin());
        return;
      }

      if (isAccessExpired && !isActive) {
        yield put(actions.redirectToLogin());
        return;
      }

      if (isActive) {
        updateSessionTimeout();
      }

      yield call(loadKc);
      const profile = jwtDecode(idToken);

      yield put({
        type: actions.LOGIN_SUCCESS,
        accessToken,
        profile,
        appStatus: 'ready',
        noRedirect: true
      });
      // Check alternate channel user has access
      let isAuthorizedUser = window.localStorage.getItem('isAuthorizedUser');
      if (isAuthorizedUser && isAuthorizedUser === 'false') {
        yield put(push('/admin'))
      }
    } catch (e) {
      yield put(actions.redirectToLogin());
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(handleAuthenticationCallback),
    fork(loginSuccess),
    fork(loginError),
    fork(login),
    fork(userRole),
    fork(logout),
    fork(redirect),
    fork(redirectToLogin)
  ]);
}